(function ($, Drupal) {
  var contentBlockSignupOverlay = {
    init: function (context) {
      var self = this;
      var $body = $('body', context);
      var $module = $('.js-content-block-signup-overlay--v1', context);
      if ($module.length < 1) {
        return false;
      }
      $body.addClass('content-block-signup-overlay--enabled');
    }
  };

  Drupal.behaviors.contentBlockSignupOverlayV1 = {
    attached: false,
    attach: function (context) {
      var self = this;

      if (!!self.attached) {
        return;
      }
      self.attached = true;
      contentBlockSignupOverlay.init(context);
    }
  };
})(jQuery, Drupal);
